import React, { useState, useEffect } from 'react';
import axios from "../../helpers/axios";
import Layout from '../home/layout';
import swal from "sweetalert";
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for react-quill

const SendMessage = () => {
    const [recipientId, setRecipientId] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [recipientError, setRecipientError] = useState('');
    const [contentError, setContentError] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/users/getAll'); // Replace with your endpoint to fetch users
            setUsers(response.data);
        } catch (err) {
            console.error('Error fetching users:', err);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setRecipientId(user._id); // Assuming user object has an `id` property
        setRecipientError('');
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Validate recipient and content
      if (!recipientId) {
          setRecipientError('Please select a recipient.');
          return;
      } else {
          setRecipientError('');
      }
  
      if (!content.trim()) {
          setContentError('Please enter message content.');
          return;
      } else {
          setContentError('');
      }
  
      setLoading(true);
      setError('');
  
      try {
          const response = await axios.post('/users/message', { recipientId, subject, content });
          console.log(response.data); // Optional: Handle success response
          swal("Success", "Message sent successfully!", "success");
          setLoading(false);
          setRecipientId('');
          setSubject('');
          setContent('');
      } catch (err) {
          setLoading(false);
          if (err.response) {
              console.error('Server error:', err.response.data); // Log server response
              setError(err.response.data.message || 'Error sending message.');
          } else {
              console.error('Network error:', err.message); // Log network error
              setError('Network error. Please try again later.');
          }
      }
  };

    return (
        <Layout>
            <Container>
                <Row className="justify-content-md-center mt-5">
                    <Col md={8}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="recipientId">
                                <Form.Label>Recipient:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedUser ? selectedUser.fullName : ''}
                                    onChange={(e) => handleUserSelect(users.find(user => user.fullName === e.target.value))}
                                    isInvalid={!!recipientError}
                                >
                                    <option value="">Select recipient...</option>
                                    {users.map(user => (
                                        <option key={user._id} value={user.fullName}>
                                            {user.fullName} ({user.role})
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {recipientError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="subject">
                                <Form.Label>Subject:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="content">
                                <Form.Label>Content:</Form.Label>
                                <ReactQuill
                                    value={content}
                                    onChange={setContent}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {contentError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {error && <Alert variant="danger">{error}</Alert>}

                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Sending...
                                    </>
                                ) : (
                                    'Send Message'
                                )}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default SendMessage;
