import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Link,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const placeholderImage = 'img/placeholder.jpg';

const roles = ['admin', 'innovator', 'user', 'partner', 'investor'];

const ManagerUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0); // State for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page
  const [stats, setStats] = useState({
    innovatorsCount: 0,
    investorsCount: 0,
    innovationsCount: 0,
    partnersCount: 0, // Added partners count
  });

  // Function to fetch statistics
  const fetchStatistics = async () => {
    try {
      const [innovatorsResponse, investorsResponse, innovationsResponse, partnersResponse] = await Promise.all([
        axios.get('https://api.ghsdginnovations.org/api/users/innovators/count'),
        axios.get('https://api.ghsdginnovations.org/api/users/investors/count'),
        axios.get('https://api.ghsdginnovations.org/api/innovations/approved/count'),
        axios.get('https://api.ghsdginnovations.org/api/users/partners/count'), // Added partners count request
      ]);

      setStats({
        innovatorsCount: innovatorsResponse.data.count,
        investorsCount: investorsResponse.data.count,
        innovationsCount: innovationsResponse.data.count,
        partnersCount: partnersResponse.data.count, // Updated partners count
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/users/getAll');
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.fullName.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [filter, users]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await axios.put(`https://api.ghsdginnovations.org/api/users/updateRole/${userId}`, { role: newRole });
      setUsers(users.map(user => (user._id === userId ? { ...user, role: newRole } : user)));
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    const roleFilters = ['user', 'partner', 'innovator', 'investor'];
    const selectedRole = roleFilters[newValue];
    const filtered = users.filter(user => user.role === selectedRole);
    setFilteredUsers(filtered);
    setPage(0); // Reset to first page on tab change
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Layout>
    <Box marginTop='100px' >
    <Typography variant="h6" gutterBottom>
          Manage Users
        </Typography>
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3}>
     
        <List sx={{ width: '100%', maxWidth: 600 }}>
          <ListItem
            alignItems="flex-start"
            sx={{
              borderRadius: '10px',
              backgroundColor: '#fff', // White background
              marginBottom: '10px',
              border: '1px solid #dcdcdc', // Border color and style
            
            }}
          >
            <ListItemAvatar>
              <Avatar alt="Avatar" src="/static/innovator.webp" />
            </ListItemAvatar>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography component="h2">
                    Innovators
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {stats.innovatorsCount}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
    
    </Grid>

  

    <Grid item xs={12} sm={6} md={3}>
     
        <List sx={{ width: '100%', maxWidth: 600 }}>
          <ListItem
            alignItems="flex-start"
            sx={{
              borderRadius: '10px',
              backgroundColor: '#fff', // White background
              marginBottom: '10px',
              border: '1px solid #dcdcdc', // Border color and style
    
            }}
          >
            <ListItemAvatar>
              <Avatar alt="Avatar" src="/static/investor.png" />
            </ListItemAvatar>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography component="h2">
                    Investors
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {stats.investorsCount}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
     
    </Grid>

    {/* Added Partners Section */}
    <Grid item xs={12} sm={6} md={3}>
      
        <List sx={{ width: '100%', maxWidth: 600 }}>
          <ListItem
            alignItems="flex-start"
            sx={{
              borderRadius: '10px',
              backgroundColor: '#fff', // White background
              marginBottom: '10px',
              border: '1px solid #dcdcdc', // Border color and style
             
            }}
          >
            <ListItemAvatar>
              <Avatar alt="Avatar" src="/static/partner.png" />
            </ListItemAvatar>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography component="h2">
                    Partners
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {stats.partnersCount}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
    
    </Grid>
  </Grid>
</Box>


      <Box marginTop='10px' paddingBottom='60px'>
       
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="user roles tabs">
          <Tab label="Users" />
          <Tab label="Partners" />
          <Tab label="Innovators" />
          <Tab label="Investors" />
        </Tabs>
        <TextField
          id="filter"
          label="Filter by name"
          value={filter}
          onChange={handleFilterChange}
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ maxWidth: 400 }} // Adjust the width as needed
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0468B1' }}>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Profile Picture</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Name</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Email</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Gender</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Role</TableCell>
                <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <Avatar
                      alt={user.fullName}
                      src={user.profilePicture || placeholderImage}
                    />
                  </TableCell>
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.gender}</TableCell>
                  <TableCell>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user._id, e.target.value)}
                        label="Role"
                      >
                        {roles.map(role => (
                          <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/manageUsers/${user._id}`}
                      underline="none"
                    >
                      View Details
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default ManagerUsers;
