import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Card, CardImg, CardBody } from "react-bootstrap";
import { Typography,Box, Autocomplete, TextField } from "@mui/material";
import { Input, Label } from "reactstrap";
import swal from "sweetalert";
import axios from "../../helpers/axios";
import useSector from "../../hooks/useSector";
import useRegion from "../../hooks/useRegion";
import useInnovationStatus from "../../hooks/useInnovtionStatus";
import useGoal from "../../hooks/useGoal";
import { useNavigate } from "react-router";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const AddInnovation = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [featuredImgUrl, setFeaturedImgUrl] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const userId = localStorage.getItem('userId');
  const [completedStep1, setCompletedStep1] = useState(false);
  const navigate = useNavigate();
  const [listOfSectors] = useSector();
  const [listOfGoals] = useGoal();
  const [listOfRegions] = useRegion();
  const [listOfInnovationStatuses] = useInnovationStatus();
  const [userRole, setUserRole] = useState('');
  const [isOwner, setIsOwner] = useState(true);

  const [wordCounts, setWordCounts] = useState({
    description: 0,
    problemStatement: 0,
    solution: 0,
    tractionAndImpact: 0,
    planForExpansion: 0,
  });

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`/users/role/${userId}`);
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const isUserInnovator = userRole === 'innovator';

  const [inputs, setInputs] = useState({
    userId: userId,
    regionId: "",
    innovationName: '',
    description: "",
    problemStatement: "",
    solution: "",
    tractionAndImpact: "",
    planForExpansion: "",
    sectorId: "",
    goalId: "", // Changed from goalIds to goalId
    innovationStatusId: "",
    webLink: "",
    featuredImg: "",
    images: [],
    firstName: "", // Added firstName field
    lastName: "", // Added lastName field
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, options } = e.target;
    if (name === "goalId") {
      setInputs({ ...inputs, [name]: value });
    } else {
      const words = value.split(/\s+/).filter(Boolean);
      if (words.length <= 200) {
        setInputs({ ...inputs, [name]: value });
        setWordCounts({ ...wordCounts, [name]: words.length });
      }
    }
    if (
      inputs.innovationName &&
      inputs.innovationStatusId &&
      inputs.sectorId &&
      inputs.regionId &&
      inputs.goalId &&
      inputs.description &&
      inputs.problemStatement &&
      inputs.solution &&
      inputs.tractionAndImpact &&
      inputs.planForExpansion
    ) {
      setCompletedStep1(true);
    } else {
      setCompletedStep1(false);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 3);
    setSelectedImages(files);

    const imageUrlsArray = [];
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        imageUrlsArray.push(reader.result);
        setImageUrls([...imageUrlsArray]);

        // Automatically set the first image as the featured image if none is selected yet
        if (featuredImgUrl === "") {
          setFeaturedImgUrl(reader.result);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSelectFeaturedImage = (imageUrl) => {
    setFeaturedImgUrl(imageUrl);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentStep === 1) {
      if (!inputs.innovationName || !inputs.innovationStatusId || !inputs.sectorId || !inputs.regionId || !inputs.goalId || !inputs.description || !inputs.problemStatement || !inputs.solution ) {
        await swal('Validation Error', 'All innovation details fields are required.', 'error');
        return;
      }
      setCompletedStep1(true);
      nextStep();
    } else if (currentStep === 2) {
      const formData = new FormData();
      selectedImages.forEach(image => {
        formData.append('images', image);
      });
      formData.append('userId', inputs.userId);
      formData.append('regionId', inputs.regionId);
      formData.append('innovationName', inputs.innovationName);
      formData.append('description', inputs.description);
      formData.append('problemStatement', inputs.problemStatement);
      formData.append('solution', inputs.solution);
      formData.append('tractionAndImpact', inputs.tractionAndImpact);
      formData.append('planForExpansion', inputs.planForExpansion);
      formData.append('sectorId', inputs.sectorId);
      formData.append('goalId', inputs.goalId); // Changed from goalIds to goalId
      formData.append('innovationStatusId', inputs.innovationStatusId);
      formData.append('webLink', inputs.webLink);
      formData.append('featuredImg', featuredImgUrl);
      formData.append('firstName', inputs.firstName); // Append firstName to formData
      formData.append('lastName', inputs.lastName); // Append lastName to formData

      try {
        const response = await axios.post('/innovations', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          navigate('/');
          swal('Success', 'Innovation uploaded successfully!', 'success');
        } else {
          swal('Upload Error', 'There was an issue uploading your innovation. Please try again.', 'error');
        }
      } catch (error) {
        console.error('Error:', error);
        swal('Upload Error', 'There was an issue uploading your innovation. Please try again.', 'error');
      }
    }
  };

  const handleImageUrlChange = (e) => {
    const url = e.target.value;
    setImageUrls([...imageUrls, url]);
  };

  const removeImage = (index) => {
    const newImageUrls = imageUrls.filter((_, i) => i !== index);
    const newSelectedImages = selectedImages.filter((_, i) => i !== index);
    setImageUrls(newImageUrls);
    setSelectedImages(newSelectedImages);

    if (featuredImgUrl === imageUrls[index]) {
      setFeaturedImgUrl(newImageUrls.length > 0 ? newImageUrls[0] : "");
    }
  };

  const handleOwnershipChange = (e) => {
    const value = e.target.value === 'yes';
    setIsOwner(value);

    if (!value) {
      setInputs({
        ...inputs,
        firstName: '',
        lastName: '',
      });
    }
  };


  return (
    <>
      <div>
        {currentStep === 1 && (
          <Form onSubmit={handleSubmit}>
           <Typography
  variant="h6"
  style={{
    backgroundColor: '#0468B1',
    color: 'white',
    padding: '8px',
    borderRadius: '8px', 
  }}
>
  INNOVATION DETAILS
</Typography>

            <br />
            <div className="form-group row">
           
            <div className="col-sm-6" >
              <Label >Are you the owner of innovation? *</Label>
              </div>
              <div className="col-sm-6" style={{ paddingLeft: '35px' }} >
              <Input
              type="radio"
              id="yes"
              name="ownership"
              value="yes"
              checked={isOwner === true}
              onChange={handleOwnershipChange}
              className="form-check-input"
            />
  <Label htmlFor="yes" className="form-check-label" style={{ marginRight: '40px' }}>Yes</Label> 

  <Input
              type="radio"
              id="no"
              name="ownership"
              value="no"
              checked={isOwner === false}
              onChange={handleOwnershipChange}
              className="form-check-input"
            />
  <Label htmlFor="no" className="form-check-label">No</Label>
</div>

              
             
            </div>
            
            {!isOwner && (
              <>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <Label for="firstName">First Name *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      value={inputs.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Label for="lastName">Last Name *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      value={inputs.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <br />
              </>
            )}

            <div className="form-group row">
              <div className="col-sm-4">
                <Label>Innovation Name *</Label>
                <Input
                  value={inputs.innovationName}
                  onChange={handleChange}
                  name="innovationName"
                />
              </div>
              <div className="col-sm-4">
                <Label>Sector *</Label>
                <Input
                  type="select"
                  name="sectorId"
                  id="sector"
                  value={inputs.sectorId}
                  onChange={handleChange}
                >
                  <option value="">Choose Sector</option>
                  {listOfSectors.map((sector) => (
                    <option value={sector._id} key={sector._id}>
                      {sector.name}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-sm-4">
                <Label>Region *</Label>
                <Input
                  type="select"
                  name="regionId"
                  id="region"
                  value={inputs.regionId}
                  onChange={handleChange}
                >
                  <option value="">Choose Region</option>
                  {listOfRegions.map((region) => (
                    <option value={region._id} key={region._id}>
                      {region.name}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
            <div className="form-group row">
      {/* Innovation Status Field */}
      <div className="col-sm-4">
        <Label>Innovation Stage *</Label>
        <Autocomplete
          options={listOfInnovationStatuses}
          getOptionLabel={(option) => option.name}
          value={listOfInnovationStatuses.find((status) => status._id === inputs.innovationStatusId) || null}
          onChange={(event, newValue) => {
            handleChange({ target: { name: 'innovationStatusId', value: newValue?._id || '' } });
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{option.name}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Select Stage" variant="outlined" />
          )}
        />
      </div>

      {/* Goal Field */}
      <div className="col-sm-4" >
        <Label>Goal *</Label>
        <Autocomplete
          options={listOfGoals}
          getOptionLabel={(option) => option.name}
          value={listOfGoals.find((goal) => goal._id === inputs.goalId) || null}
          onChange={(event, newValue) => {
            
            handleChange({ target: { name: 'goalId', value: newValue?._id || '' } });
            
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={option.icon} alt={option.name} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
              <Typography>{option.name}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="select Goal" variant="outlined" />
          )}
        />
      </div>
    </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Label>Description *</Label>
                <Input
                  type="textarea"
                  name="description"
                  rows="4"
                  value={inputs.description}
                  onChange={handleChange}
                />
                <small>{wordCounts.description}/200</small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Label>Problem Statement *</Label>
                <Input
                  type="textarea"
                  name="problemStatement"
                  rows="4"
                  value={inputs.problemStatement}
                  onChange={handleChange}
                />
                <small>{wordCounts.problemStatement}/200</small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Label>Solution *</Label>
                <Input
                  type="textarea"
                  name="solution"
                  rows="4"
                  value={inputs.solution}
                  onChange={handleChange}
                />
                <small>{wordCounts.solution}/200</small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Label>Traction and Impact </Label>
                <Input
                  type="textarea"
                  name="tractionAndImpact"
                  rows="4"
                  value={inputs.tractionAndImpact}
                  onChange={handleChange}
                />
                <small>{wordCounts.tractionAndImpact}/100</small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Label>Plan for Expansion </Label>
                <Input
                  type="textarea"
                  name="planForExpansion"
                  rows="4"
                  value={inputs.planForExpansion}
                  onChange={handleChange}
                />
                <small>{wordCounts.planForExpansion}/100</small>
              </div>
            </div>
            <Button 
  type="button" 
  variant="contained"
  onClick={nextStep} 
  disabled={!completedStep1} 
  style={{ backgroundColor: '#0468B1', borderColor: '#0468B1', color:"white" }}
>
  Next
</Button>

          </Form>
        )}

        {currentStep === 2 && (
          <Form onSubmit={handleSubmit}>
            <Typography
              variant="h6"
              style={{
                backgroundColor: '#0468B1',
                color: 'white',
                padding: '8px',
                borderRadius: '8px',
              }}
            >
              UPLOAD IMAGES
            </Typography>
            <br />
            <div className="form-group row">
  <div className="col-sm-12">
    <Label>Upload Images *</Label>
    <Input
      type="file"
      accept="image/*"
      multiple
      onChange={handleImageChange}
    />
  </div>
</div>

<div className="form-group row">
  <div className="col-sm-12 d-flex justify-content-between flex-wrap">
    {imageUrls.map((url, index) => (
      <Card key={index} style={{ width: '18rem', padding: "10px", marginBottom: '10px' }}>
        <CardImg variant="top" src={url} />
        <CardBody className="d-flex justify-content-between">
          <Button
            onClick={() => handleSelectFeaturedImage(url)}
            variant="contained"
            style={{
              backgroundColor: featuredImgUrl === url ? '#0468B1' : 'gray',
              borderColor: featuredImgUrl === url ? '#0468B1' : 'gray',
              color: 'white',
            }}
          >
            {featuredImgUrl === url ? 'Featured' : 'Set as Featured'}
          </Button>
          <IconButton onClick={() => removeImage(index)} aria-label="delete" color="error">
            <DeleteIcon />
          </IconButton>
        </CardBody>
      </Card>
    ))}
  </div>
</div>


{/* <div className="form-group row">
  <div className="col-sm-12">
    <Label>Featured Image *</Label>
    {featuredImgUrl && (
      <Card style={{ width: '18rem' }}>
        <CardImg variant="top" src={featuredImgUrl} />
      </Card>
    )}
  </div>
</div> */}
           
          
            <Button type="button"  
  variant="contained" style={{ backgroundColor: '#0468B1', borderColor: '#0468B1', color:"white" }} onClick={prevStep}>Back</Button>
            <Button type="submit" 
  variant="contained"  style={{ backgroundColor: '#0468B1', borderColor: '#0468B1', color:"white" }}>Submit</Button>
          </Form>
        )}
      </div>
    </>
  );
};

export default AddInnovation;
