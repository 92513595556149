import React, { useEffect, useState } from 'react';
import axios from "../../helpers/axios"; // Adjust the import path for axios as per your project structure
import Layout from '../home/layout'; // Ensure correct import path for Layout
import { useParams } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';

const MessageDetail = () => {
    const { messageId } = useParams(); // Fetch message ID from URL params
    const [userId, setUserId] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const token = localStorage.getItem('accessToken'); // Fetch token from localStorage
                if (!token) {
                    throw new Error('No token available');
                }
                const response = await axios.get('/users/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(response.data._id);
            } catch (err) {
                setError('Error fetching user information.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        const fetchMessageDetail = async () => {
            try {
                const token = localStorage.getItem('accessToken'); // Fetch token from localStorage
                if (!token) {
                    throw new Error('No token available');
                }
                const response = await axios.get(`/users/message/${userId}/${messageId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMessage(response.data); // Set the entire message object from API response
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    setError('Access denied. You are not authorized to view this message.');
                } else {
                    setError(err.response?.data?.message || err.message || 'Error fetching message details.');
                }
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchMessageDetail();
        }
    }, [userId, messageId]);

    // If token is not available, prompt user to log in
    if (!localStorage.getItem('accessToken')) {
        return (
            <Layout>
                <Alert variant="info">Please log in to view this message.</Alert>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="message-detail-container">
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : message ? (
                    <div>
                        <h2>{message.subject}</h2>
                        <p>From: {message.sender.fullName}</p>
                        <p>Sent: {new Date(message.createdAt).toLocaleString()}</p>
                        <hr />
                        <p>{message.content}</p>
                    </div>
                ) : (
                    <Alert variant="info">Message not found.</Alert>
                )}
            </div>
        </Layout>
    );
};

export default MessageDetail;
