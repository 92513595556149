import axios from "../helpers/axios";
import { authConstants } from "./constants";

export const login = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });
    const res = await axios.post(`/auth/login`, {
      ...user,
    });
    if (res.status === 200) {
      console.log('user res', res);
      const { token, userId, role } = res.data; // Include role in the response
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("role", role); // Store role in local storage
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          userId,
          role, // Include role in payload
        },
      });
    } else {
      if (res.status === 400) {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: res.data.error },
        });
      }
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role"); // Retrieve role from local storage
    if (token && role) {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          role, // Include role in payload
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "Failed to login" },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({
      type: authConstants.LOGOUT_REQUEST,
    });
  };
};
