import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
    bgcolor="#0468B1"
    color="white"
    textAlign="center"
    padding={2}
    position="fixed"
    bottom={0}
    left={0}
    width="100%"
  >
      
      <Typography variant="body2">
        Ghana Innovation Portal (c) 2023. All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
