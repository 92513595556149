import { useState, useEffect } from 'react';
import { api } from '../urlConfig';
import axios from 'axios';

function useInnovationStatus() {
  const [listOfInnovationStatuses, setListOfInnovationStatuses] = useState([]);

  useEffect(() => {
    axios.get(`${api}status/all`).then((response) => {
      setListOfInnovationStatuses(response.data);
    }).catch((error) => {
      // Handle error if the request fails
      console.error('Error fetching innovation statuses:', error);
    });
  }, []);

  return [listOfInnovationStatuses];
}

export default useInnovationStatus;
