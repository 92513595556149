export const authConstants = {
    LOGIN_REQUEST:'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST'
}


export const userContants = {
    USER_REGISTER_REQUEST:'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE'
  
}

export const productConstants = {
    GET_ALL_PRODUCTS_REQUEST: "GET_ALL_PRODUCTS_REQUEST",
    GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
    GET_ALL_PRODUCTS_FAILURE: "GET_ALL_PRODUCTS_FAILURE",
  
    DELETE_PRODUCT_BY_ID_REQUEST: "DELETE_PRODUCT_BY_ID_REQUEST",
    DELETE_PRODUCT_BY_ID_SUCCESS: "DELETE_PRODUCT_BY_ID_SUCCESS",
    DELETE_PRODUCT_BY_ID_FAILURE: "DELETE_PRODUCT_BY_ID_FAILURE",
  
    ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  }

  export const innovationConstants = {
    GET_ALL_INNOVATIONS_REQUEST: "GET_ALL_INNOVATIONS_REQUEST",
    GET_ALL_INNOVATIONS_SUCCESS: "GET_ALL_INNOVATIONS_SUCCESS",
    GET_ALL_INNOVATIONS_FAILURE: "GET_ALL_INNOVATIONS_FAILURE",
  
    DELETE_INNOVATION_BY_ID_REQUEST: "DELETE_INNOVATION_BY_ID_REQUEST",
    DELETE_INNOVATION_BY_ID_SUCCESS: "DELETE_INNOVATION_BY_ID_SUCCESS",
    DELETE_INNOVATION_BY_ID_FAILURE: "DELETE_INNOVATION_BY_ID_FAILURE",
  
    ADD_INNOVATION_REQUEST: "ADD_INNOVATION_REQUEST",
    ADD_INNOVATION_SUCCESS: "ADD_INNOVATION_REQUEST",
    ADD_INNOVATION_FAILURE: "ADD_INNOVATION_FAILURE",
  };