import React, {useState} from 'react'
import {Box, styled} from '@mui/system'
import {Card, Grid, Button, Stack} from '@mui/material'
import {Input, Form} from 'reactstrap';
import {useNavigate} from "react-router";
import swal from 'sweetalert'
import Logo from "../../components/Logo";
import axios from "../../helpers/axios";
import {Span} from "../../components/Typography";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

const ContentBox = styled(JustifyBox)(() => ({
    height: '100%',
    padding: '32px',
    background: 'rgba(0, 0, 0, 0.01)',
}))
styled('img')(() => ({
    width: '100%',
}));
const JWTRegister = styled(JustifyBox)(() => ({
    background: '#1A2038',
    minHeight: '100vh !important',
    '& .card': {
        maxWidth: 900,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const formDataInitial = {
    fullName: '',
    email: '',
    password: ''
};

const Register = () => {

    const [formData, setFormData] = useState(formDataInitial);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
      }
      

    const userSignup = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true)
            await axios.post(`/auth/register`, formData);
            setIsLoading(false)
            await swal('Success', 'Account sign up successful', 'success')
            navigate('/login')
        } catch (err) {
            setIsLoading(false)
            let message;
            if(err.response.status === 422){
                message = err.response.data.error;
            }else{
                message = 'An error occurred. Please try again!';
            }
            await swal('Sign up Error', message, 'error')
        }


    }


    return (
        <JWTRegister>
            <Card className="card">

                <Grid container  justifyContent="center" alignItems="center">
                    {/* <Grid item lg={5} md={5} sm={5} xs={12}>
                        <ContentBox>
                            <Logo/>
                        </ContentBox>
                    </Grid> */}
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <Box p={4} height="100%" marginBottom="100">
                        <Logo/>
                        <br/>
                        <br/>
                        <h4>SIGN UP</h4>
                            <Form onSubmit={userSignup}>
                                <Stack spacing={4}>
                                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>

                                        <Input
                                            sx={{mb: 3, width: '100%'}}
                                            variant="outlined"
                                            label="FullName"
                                            bsSize="small"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            name="fullName" type="text" placeholder="Full Name"
                                            required/>
                                    </Stack>
                                    <Input
                                        sx={{mb: 3, width: '100%'}}
                                        variant="outlined"
                                        bsSize="small"
                                        label="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        name="email" type="text" placeholder="Email" required/>
                                    <Input
                                        sx={{mb: '16px', width: '100%'}}
                                        label="Password"
                                        variant="outlined"
                                        bsSize="small"
                                        value={formData.password}
                                        onChange={handleChange}
                                        name="password" type="password"
                                        placeholder="Password" required/>
                                </Stack>
                                <br/>
                                <FlexBox>
                                    <Button
                                        type="submit"
                                       
                                        className="btn btn-warning col m-0"
                                        value="Register"
                                        disabled={isLoading}
                                        sx={{textTransform: 'capitalize'}}>
                                        {isLoading ? 'Please wait...' : 'Sign up'}
                                    </Button>
                                    <Span sx={{mr: 1, ml: '20px'}}>or</Span>
                                    <Button
                                        sx={{textTransform: 'capitalize'}}
                                        onClick={() => navigate("/login/")}>
                                        Sign in
                                    </Button>
                                </FlexBox>
                            </Form>
                        </Box>
                    </Grid>
                </Grid>
            
            </Card>
        </JWTRegister>
    
    )
}

export default Register;
