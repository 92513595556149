import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import axios from 'axios';

const PieChartComponent = () => {
  const [stats, setStats] = useState({ approvedCount: 0, rejectedCount: 0, pendingCount: 0 });

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const [approvedResponse, rejectedResponse, pendingResponse] = await Promise.all([
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/approved/count', config),
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/rejected/count', config),
          axios.get('https://api.ghsdginnovations.org/api/admin/innovations/pending/count', config),
        ]);

        setStats({
          approvedCount: approvedResponse.data.count || 0,
          rejectedCount: rejectedResponse.data.count || 0,
          pendingCount: pendingResponse.data.count || 0,
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, []);

  useEffect(() => {
    if (stats.approvedCount === 0 && stats.rejectedCount === 0 && stats.pendingCount === 0) return;

    const root = am5.Root.new('pieChartdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
      })
    );

    series.data.setAll([
      { category: 'Approved', value: stats.approvedCount },
      { category: 'Rejected', value: stats.rejectedCount },
      { category: 'Pending', value: stats.pendingCount },
    ]);

    series.slices.template.setAll({
      tooltipText: '{category}: {value}',
      interactive: true,
    });

    series.slices.template.adapters.add('fill', (fill, target) => {
      const category = target.dataItem.dataContext.category;
      switch (category) {
        case 'Approved':
          return am5.color('#4caf50'); // Green
        case 'Rejected':
          return am5.color('#f44336'); // Red
        case 'Pending':
          return am5.color('#ffeb3b'); // Light Yellow
        default:
          return fill; // Default color if category is not matched
      }
    });

    series.labels.template.setAll({
      text: '{category}',
    });

    series.ticks.template.setAll({
      visible: true,
    });

    // Cleanup
    return () => {
      root.dispose();
    };
  }, [stats]);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div id="pieChartdiv" style={{ width: '100%', height: '100%', maxWidth: '600px', minHeight: '300px' }}></div>
    </div>
  );
};

export default PieChartComponent;
