import { useState, useEffect } from 'react';
import { api } from '../urlConfig';
import axios from 'axios';

function useGoal() {
  const [listOfgoals, setListOfGoals] = useState([]);

  useEffect(() => {
    axios.get(`${api}goals/all`).then((response) => {
      setListOfGoals(response.data);
    }).catch((error) => {
      // Handle error if the request fails
      console.error('Error fetching goals:', error);
    });
  }, []);

  return [listOfgoals];
}

export default useGoal;
