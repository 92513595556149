import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const endpoints = [
  "https://api.ghsdginnovations.org/api/innovations/get/66ae6f70de86e60be27e759e/innovation-count", // No Poverty
  "https://api.ghsdginnovations.org/api/innovations/get/66ae6fbcde86e60be27e75a1/innovation-count", // Zero Hunger
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7018de86e60be27e75a4/innovation-count", // Good Health and Well-Being
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7045de86e60be27e75a7/innovation-count", // Quality Education
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7060de86e60be27e75a9/innovation-count", // Gender Equality
  "https://api.ghsdginnovations.org/api/innovations/get/66ae70a0de86e60be27e75ac/innovation-count", // Clean Water and Sanitation
  "https://api.ghsdginnovations.org/api/innovations/get/66ae70c6de86e60be27e75ae/innovation-count", // Affordable and Clean Energy
  "https://api.ghsdginnovations.org/api/innovations/get/66ae70efde86e60be27e75b0/innovation-count", // Decent Work and Economic Growth
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7125de86e60be27e75b2/innovation-count", // Industry, Innovations and Infrastructure
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7148de86e60be27e75b4/innovation-count", // Reduced Inequalities
  "https://api.ghsdginnovations.org/api/innovations/get/66ae716dde86e60be27e75b6/innovation-count", // Sustainable Cities and Communities
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7196de86e60be27e75b8/innovation-count", // Responsible Consumption and Production
  "https://api.ghsdginnovations.org/api/innovations/get/66ae71a9de86e60be27e75ba/innovation-count", // Climate Action
  "https://api.ghsdginnovations.org/api/innovations/get/66ae71c8de86e60be27e75bc/innovation-count", // Life Below Water
  "https://api.ghsdginnovations.org/api/innovations/get/66ae71dbde86e60be27e75be/innovation-count", // Life On Land
  "https://api.ghsdginnovations.org/api/innovations/get/66ae720dde86e60be27e75c0/innovation-count", // Peace, Justice and Strong Institutions
  "https://api.ghsdginnovations.org/api/innovations/get/66ae7234de86e60be27e75c2/innovation-count"  // Partnerships for the Goals
];

const icons = [
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706692/SDG%20GOALS/zhotss31i9q8exepyxmb.png", // No Poverty
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/nzomifevlzwxa5vdz5j0.png", // Zero Hunger
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/xxicysdsxztvhwgdltac.png", // Good Health and Well-Being
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/oxgcuvxyljpukazfqej0.png", // Quality Education
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/twlmqhi78nimcvms2jcv.png", // Gender Equality
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706694/SDG%20GOALS/qlkn3hknkrv86lkpyaae.png", // Clean Water and Sanitation
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706694/SDG%20GOALS/gxnp0tgemhi1omdlvnho.png", // Affordable and Clean Energy
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/nzomifevlzwxa5vdz5j0.png", // Decent Work and Economic Growth
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/xxicysdsxztvhwgdltac.png", // Industry, Innovations and Infrastructure
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/oxgcuvxyljpukazfqej0.png", // Reduced Inequalities
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/twlmqhi78nimcvms2jcv.png", // Sustainable Cities and Communities
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706694/SDG%20GOALS/qlkn3hknkrv86lkpyaae.png", // Responsible Consumption and Production
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706694/SDG%20GOALS/ww6zl97wv0eh3luz2kno.png", // Climate Action
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/g2kfydnzpnmtuiayph0c.png", // Life Below Water
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/lcn7yssvpqy08gtxlfvu.png", // Life On Land
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706693/SDG%20GOALS/ag3avggrbf8y27ig6slk.png", // Peace, Justice and Strong Institutions
  "https://res.cloudinary.com/sdginnovations/image/upload/v1722706694/SDG%20GOALS/xalrnetywb69spf4wxuj.png"  // Partnerships for the Goals
];

const sdgNames = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-Being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitation",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovations and Infrastructure",
  "Reduced Inequalities",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life On Land",
  "Peace, Justice and Strong Institutions",
  "Partnerships for the Goals"
];

const SDGChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data from all endpoints
        const counts = await Promise.all(endpoints.map(endpoint =>
          fetch(endpoint)
            .then(response => response.json())
            .catch(error => {
              console.error(`Error fetching from ${endpoint}:`, error);
              return { innovationCount: 0 }; // Default count in case of error
            })
        ));

        // Prepare the data for the chart
        const updatedData = sdgNames.map((name, index) => ({
          name,
          innovationCount: counts[index]?.innovationCount || 0,
          icon: { src: icons[index] }
        }));

        setData(updatedData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    let root = am5.Root.new('chartdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        paddingRight: 31,
        wheelX: "none",
        wheelY: "none"
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, { minorGridEnabled: true });
    yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "name",
        renderer: yRenderer,
        paddingRight: 40
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 80, minorGridEnabled: true });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, { min: 0, renderer: xRenderer })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Innovation Count",
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: "innovationCount",
        categoryYField: "name",
        sequencedInterpolation: true,
        calculateAggregates: true,
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, { dy: -30, pointerOrientation: "vertical", labelText: "{valueX}" })
      })
    );

    series.columns.template.setAll({
      strokeOpacity: 0,
      cornerRadiusBR: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusTL: 10,
      maxHeight: 40,
      fillOpacity: 0.8
    });

    let currentlyHovered;

    series.columns.template.events.on("pointerover", function (e) {
      handleHover(e.target.dataItem);
    });

    series.columns.template.events.on("pointerout", function () {
      handleOut();
    });

    function handleHover(dataItem) {
      if (dataItem && currentlyHovered !== dataItem) {
        handleOut();
        currentlyHovered = dataItem;
        let bullet = dataItem.bullets[0];
        bullet.animate({
          key: "locationX",
          to: 1,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }

    function handleOut() {
      if (currentlyHovered) {
        let bullet = currentlyHovered.bullets[0];
        bullet.animate({
          key: "locationX",
          to: 0,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }

    let circleTemplate = am5.Template.new({});

    series.bullets.push(function (root, series, dataItem) {
      let bulletContainer = am5.Container.new(root, {});
      let circle = bulletContainer.children.push(
        am5.Circle.new(root, { radius: 20 }, circleTemplate)
      );

      let maskCircle = bulletContainer.children.push(
        am5.Circle.new(root, { radius: 27 })
      );

      let imageContainer = bulletContainer.children.push(
        am5.Container.new(root, { mask: maskCircle })
      );

      let image = imageContainer.children.push(
        am5.Picture.new(root, {
          templateField: "icon",
          centerX: am5.p50,
          centerY: am5.p50,
          width: 40,
          height: 40
        })
      );

      return am5.Bullet.new(root, {
        locationX: 0,
        sprite: bulletContainer
      });
    });

    series.set("heatRules", [
      {
        dataField: "valueX",
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: series.columns.template,
        key: "fill"
      },
      {
        dataField: "valueX",
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: circleTemplate,
        key: "fill"
      }
    ]);

    series.data.setAll(data);
    yAxis.data.setAll(data);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    cursor.events.on("cursormoved", function () {
      let dataItem = series.get("tooltip").dataItem;
      if (dataItem) {
        handleHover(dataItem);
      } else {
        handleOut();
      }
    });

    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="chartdiv" style={{ width: '100%', height: '800px' }}></div>;
};

export default SDGChart;