import authReducers from "./auth.reducers";
import userReducer from "./user.reducer";
import productReducer from "./product.reducer";
import { combineReducers } from "redux";
import businessReducer from "./business.reducer";

const rootReducer = combineReducers({
  auth: authReducers,
  user: userReducer,
  product: productReducer,
  business: businessReducer,
});

export default rootReducer;
