export const FETCH_BUSINESS_SUCCESS = "fetch-business-success";
export const ADD_BUSINESS_SUCCESS = "add-business-success";
export const UPDATE_BUSINESS_SUCCESS = "update-business-success";

const initialState = {
  businesses: [],
};
const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_SUCCESS:
      return {
        businesses: action.payload,
      };
    case ADD_BUSINESS_SUCCESS:
      return {
        businesses: [...state.businesses, action.payload],
      };

    case UPDATE_BUSINESS_SUCCESS:
      return {
        businesses: state.businesses.map((business) => {
          if (business._id === action.payload._id) {
            return {
              ...business,
              ...action.payload,
            };
          } else {
            return business;
          }
        }),
      };
    default:
      return state;
  }
};

export default businessReducer;
