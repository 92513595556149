import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from './layout';
import { Avatar, Typography, Grid, TextField } from '@mui/material';

const defaultCoverPicture = "https://traveloffice.org/wp-content/uploads/2018/09/park-bench-fall.jpg";
const defaultProfilePicture = "https://via.placeholder.com/150"; // Placeholder for profile picture

const InvestorDetailPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://api.ghsdginnovations.org/api/users/${id}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return (
      <Layout>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container spacing={3} style={{ marginTop: '50px', marginBottom: '100px' }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center", borderRadius: "10px", overflow: "hidden" }}>
            <img
              src={user.coverPicture || defaultCoverPicture}
              alt="Cover Photo"
              width="100%"
              height={300}
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: '-75px' }}>
            <Avatar
              src={user.profilePicture || defaultProfilePicture}
              alt="Profile Image"
              style={{ width: '150px', height: '150px', border: '5px solid white' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
          <TextField
            label="Full Name"
            name="fullName"
            value={user.fullName}
            fullWidth
            disabled
            style={{ marginBottom: '16px' }}
          />
          {/* <TextField
            label="Email"
            name="email"
            value={user.email}
            fullWidth
            disabled
            style={{ marginBottom: '16px' }}
          /> */}
          {/* <TextField
            label="Phone"
            name="phone"
            value={user.phone}
            fullWidth
            disabled
          /> */}
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
          <TextField
            label="Gender"
            name="gender"
            value={user.gender}
            fullWidth
            disabled
           
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Address"
            name="address"
            value={user.address}
            fullWidth
            disabled
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="City"
            name="city"
            value={user.city}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default InvestorDetailPage;
