import React, { useState } from 'react';
import SendMessage from './sendMessage';
import ReceivedMessages from './receivedMessages';
import Layout from '../home/layout';
import { Tab, Tabs } from 'react-bootstrap';
import SentMessages from './sent';

const Message = () => {
    const [activeTab, setActiveTab] = useState('inbox');

    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
  return (
    <div style={{ marginTop: '100px', marginBottom: '60px', textAlign: 'center' }}>
    <Layout>
        <div className="message-container">
            <div className="message-header">
                
                <Tabs
                    id="message-tabs"
                    activeKey={activeTab}
                    onSelect={(tab) => handleTabChange(tab)}
                    className="mb-3"
                >
                    <Tab eventKey="inbox" title="Inbox">
                        <ReceivedMessages />
                    </Tab>
                    <Tab eventKey="sent" title="Compose">
                        <SendMessage />
                    </Tab>
                    <Tab eventKey="outbox" title="Sent">
                    <SentMessages/>
                    </Tab>
                </Tabs>
            </div>
        </div>
    </Layout>
</div>
  );
};

export default Message;