import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Menu,
  MenuItem,
  TablePagination,
  Paper,
  Tooltip,
  Input
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Layout from './layout';
import useUserInnovation from '../../hooks/userInnovations';
import { deleteInnovationById } from '../../actions';
import AddInnovation from '../../features/innovations/AddInnovation';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { fetchUserDetails } from '../../hooks/fetchUserDetails';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; 
import { IconButton } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload'; 
import { useDropzone } from 'react-dropzone';
import swal from "sweetalert";


const Innovations = () => {
  const [listOfInnovations] = useUserInnovation();
  const [filter, setFilter] = useState('all');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentInnovation, setCurrentInnovation] = useState(null);
  const [updatedInnovation, setUpdatedInnovation] = useState({
    innovationName: '',
    description: '',
    firstName: '',
    lastName: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateTrigger, setUpdateTrigger] = useState(0); 
  const [file, setFile] = useState(null);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    const fetchAllUserDetails = async () => {
        const details = {};
        for (const innovation of listOfInnovations) {
            if (innovation.userId && !details[innovation.userId]) {
                try {
                    details[innovation.userId] = await fetchUserDetails(innovation.userId);
                } catch (error) {
                    console.error(`Error fetching details for user ${innovation.userId}:`, error);
                }
            }
        }
        setUserDetails(details);
    };

    fetchAllUserDetails();
}, [listOfInnovations]);


  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = async (id, action) => {
    const token = localStorage.getItem('token');
    const endpoint = `https://api.ghsdginnovations.org/api/admin/innovations/${id}/${action}`;

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} the innovation`);
      }

      setMessage(`Innovation ${action}d successfully`);
      setOpenSnackbar(true);
      console.log(`Innovation ${action}d successfully`);

      window.location.reload();
    } catch (error) {
      setMessage(`Error ${action}ing innovation: ${error.message}`);
      setOpenSnackbar(true);
      console.error(`Error ${action}ing innovation:`, error.message);
    }
  };

  const handleDelete = async (id) => {
    await deleteInnovationById(id);
    window.location.reload();
  };

  const handleEditOpen = (innovation) => {
    setCurrentInnovation(innovation);
    setUpdatedInnovation({
      innovationName: innovation.innovationName,
      description: innovation.description,
      firstName: innovation.firstName,
      lastName: innovation.lastName,
    });
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setCurrentInnovation(null);
  };

  const handleEditSave = async () => {
    if (!currentInnovation) return;

    const token = localStorage.getItem('token');
    const endpoint = `https://api.ghsdginnovations.org/api/admin/innovations/${currentInnovation._id}`;

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedInnovation),
      });

      if (!response.ok) {
        throw new Error('Failed to update the innovation');
      }

      setMessage('Innovation updated successfully');
      setOpenSnackbar(true);
      console.log('Innovation updated successfully');

      setEditModalOpen(false);
      setUpdateTrigger(prev => prev + 1);
    } catch (error) {
      setMessage(`Error updating innovation: ${error.message}`);
      setOpenSnackbar(true);
      console.error('Error updating innovation:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInnovation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const innovations = XLSX.utils.sheet_to_json(worksheet);
  
      const token = localStorage.getItem('token');
      const endpoint = `https://api.ghsdginnovations.org/api/admin/innovations/bulkUpload`;
  
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(innovations),
        });
  
        if (!response.ok) {
          throw new Error('Failed to upload innovations');
        }
  
        swal('Success', 'Innovation uploaded successfully!', 'success');
      } catch (error) {
        console.error('Error:', error);
        swal('Upload Error', 'There was an issue uploading your innovation. Please try again.', 'error');
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  let filteredInnovations = [];
  if (filter === 'approved') {
    filteredInnovations = listOfInnovations.filter((innovation) => innovation.approved);
  } else if (filter === 'pending') {
    filteredInnovations = listOfInnovations.filter((innovation) => !innovation.approved && !innovation.archived && !innovation.rejected);
  } else if (filter === 'archived') {
    filteredInnovations = listOfInnovations.filter((innovation) => innovation.archived);
  } else if (filter === 'rejected') {
    filteredInnovations = listOfInnovations.filter((innovation) => innovation.rejected);
  } else {
    filteredInnovations = listOfInnovations;
  }
  const paginatedInnovations = filteredInnovations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Innovations List', 10, 10);
    doc.autoTable({
      startY: 20, 
      head: [['Innovation Name', 'Submitted by', 'Innovator', 'Status']],
      body: paginatedInnovations.map((innovation) => [
        innovation.innovationName,
        userDetails[innovation.userId]?.fullName || 'Unknown',
        innovation.firstName|| 'Unknown',
        innovation.approved ? 'Approved' : innovation.archived ? 'Archived' : innovation.rejected ? 'Rejected' : 'Pending'
      ]),
    });
    doc.save('innovations.pdf');
  };
  

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(paginatedInnovations.map((innovation) => ({
      'Innovation Name': innovation.innovationName,
      'Submitted by':  userDetails[innovation.userId]?.fullName || 'Unknown',
      'Innovator': innovation.firstName  || 'Unknown',
      'Status': innovation.approved ? 'Approved' : innovation.archived ? 'Archived' : innovation.rejected ? 'Rejected' : 'Pending',
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Innovations');
    XLSX.writeFile(wb, 'innovations.xlsx');
  };



  return (
    <Layout>
      <Box marginTop='70px' marginBottom='100px'>
        <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
     

  <Tooltip title="Bulk Upload" arrow>

  <Button  marginRight="20px" onClick={() => setOpenFileDialog(true)} color="primary">
  <UploadIcon style={{ marginRight: '8px' }} /> Bulk Upload
          </Button>
     
    </Tooltip>
 

        <Tooltip title="Add Single Innovation" arrow>
          <Button
            onClick={handleOpenModal}
          
            color="primary"
           
          >
           <AddIcon />
           Add Innovation
          </Button>
          </Tooltip>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="lg"
            PaperProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '650px',
                minHeight: '200px',
                borderRadius: 16,
              },
            }}
          >
            <DialogTitle>Add New</DialogTitle>
            <DialogContent>
              <AddInnovation />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} style={{ color: "red" }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <br/>
      
        <Box display='flex' justifyContent='space-between' marginBottom='20px'>
          <div>
            <Button
              onClick={() => handleFilterChange('all')}
              variant={filter === 'all' ? 'contained' : 'outlined'}
              color='primary'
            >
              All
            </Button>
            <Button
              onClick={() => handleFilterChange('approved')}
              variant={filter === 'approved' ? 'contained' : 'outlined'}
              color='primary'
              style={{ marginLeft: '10px' }}
            >
              Approved
            </Button>
            <Button
              onClick={() => handleFilterChange('pending')}
              variant={filter === 'pending' ? 'contained' : 'outlined'}
              color='primary'
              style={{ marginLeft: '10px' }}
            >
              Pending
            </Button>
            <Button
              onClick={() => handleFilterChange('archived')}
              variant={filter === 'archived' ? 'contained' : 'outlined'}
              color='primary'
              style={{ marginLeft: '10px' }}
            >
              Archived
            </Button>
            <Button
              onClick={() => handleFilterChange('rejected')}
              variant={filter === 'rejected' ? 'contained' : 'outlined'}
              color='primary'
              style={{ marginLeft: '10px' }}
            >
              Rejected
            </Button>
          </div>
          <div>
          <Tooltip title="Export to PDF" arrow>
    <IconButton
      onClick={exportToPDF}
      style={{
        marginRight: '8px',
        transition: 'transform 0.3s, background-color 0.3s',
        borderRadius: '50%',
        padding: '8px', // Adjust padding if needed
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
    >
      <img
        src="/static/pdf.png" // Replace with your image path
        alt="Export to PDF"
        style={{
          width: '34px', // Adjust size if needed
          height: '34px',
        }}
      />
    </IconButton>
  </Tooltip>

  <Tooltip title="Export to Excel" arrow>
    <IconButton
      onClick={exportToExcel}
      style={{
        marginRight: '8px',
        transition: 'transform 0.3s, background-color 0.3s',
        borderRadius: '50%',
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
    >
     <img
        src="/static/excel.png" // Replace with your image path
        alt="Export to Excel"
        style={{
          width: '34px', // Adjust size if needed
          height: '34px',
        }}
      />
    </IconButton>
  </Tooltip>
</div>

        </Box>
        {filteredInnovations.length === 0 ? (
          <Typography variant="h6" style={{ color: '#0468B1' }}>
            No innovations found.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
               <TableHead>
                  <TableRow sx={{ backgroundColor: '#0468B1' }}>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Innovation Name
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Submitted by
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Innovator
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
  {paginatedInnovations.map((innovation) => (
    <TableRow
      key={innovation._id}
      style={{
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      }}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f5f5f5'}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'inherit'}
    >
      <TableCell>
        <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography sx={{ color: '#0468B1' }}>
                          {innovation.innovationName}
                        </Typography>
        </Link>
      </TableCell>
      {/* <TableCell>
        <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {innovation.description}
        </Link>
      </TableCell> */}
      <TableCell>
        <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {userDetails[innovation.userId]?.fullName}
        </Link>
      </TableCell>
      <TableCell>
  <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
    {innovation.firstName && innovation.lastName
      ? `${innovation.firstName} ${innovation.lastName}`
      : userDetails[innovation.userId]?.fullName || 'Unknown'}
  </Link>
</TableCell>
      <TableCell>
        <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <span style={{ 
            color: innovation.approved ? 'green' : innovation.archived ? 'gray' : innovation.rejected ? 'red' : 'orange' 
          }}>
            {innovation.approved ? 'Approved' : innovation.archived ? 'Archived' : innovation.rejected ? 'Rejected' : 'Pending'}
          </span>
        </Link>
      </TableCell>
      <TableCell style={{ boxShadow: 'none' }}>
        <Button
          variant="text"
          color="primary"
          onClick={handleMenuOpen}
          startIcon={<MoreVertIcon />}
        >
          Actions
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ '& .MuiPaper-root': { boxShadow: 'none' } }}
        >
          <MenuItem onClick={() => handleDelete(innovation._id)}>View</MenuItem>
          <MenuItem onClick={() => handleEditOpen(innovation)}>Edit</MenuItem>
          <MenuItem onClick={() => handleAction(innovation._id, 'archive')}>Archive</MenuItem>
          <MenuItem onClick={() => handleDelete(innovation._id)}>Delete</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

            </Table>
            <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredInnovations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
          </TableContainer>
        )}
       
      </Box>
      <Dialog open={editModalOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Innovation</DialogTitle>
        <DialogContent>
          <TextField
            label="Innovation Name"
            name="innovationName"
            value={updatedInnovation.innovationName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={updatedInnovation.description}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="First Name"
            name="firstName"
            value={updatedInnovation.firstName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={updatedInnovation.lastName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openFileDialog} onClose={() => setOpenFileDialog(false)}>
        <DialogTitle>Upload Innovations</DialogTitle>
        <DialogContent>
          <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Typography>Drag & drop a file here, or click to select one</Typography>
            {file && <Typography>Selected file: {file.name}</Typography>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileDialog(false)}>Cancel</Button>
          <Button onClick={handleFileUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
      />
    </Layout>
  );
};

export default Innovations;
