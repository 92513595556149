import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Grid, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, CardActions, CardMedia, Card, TextField } from '@mui/material';
import Layout from '../../pages/home/layout';
import axios from "../../helpers/axios";
import EditInnovationForm from '../innovations/EditInnovation';
import Carousel from 'react-material-ui-carousel';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const InnovationDetail = () => {
  const { id } = useParams();
  const [innovation, setInnovation] = useState(null);
  const [listOfSectors, setListOfSectors] = useState([]);
  const [listOfStatuses, setListOfStatuses] = useState([]);
  const [isUserInnovation, setIsUserInnovation] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [liked, setLiked] = useState(false);
  const [numberOfLikes, setNumberOfLikes] = useState(0);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [contactForm, setContactForm] = useState({ name: '', email: '', message: '' });
  const currentUserId = localStorage.getItem('userId');
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const fetchInnovationDetail = async () => {
      try {
        const response = await axios.get(`/innovations/${id}`);
        setInnovation(response.data);
        setIsUserInnovation(response.data.userId === currentUserId);

        const creatorResponse = await axios.get(`/users/${response.data.userId}`);
        setCreatorName(`${creatorResponse.data.fullName}`);
        setNumberOfLikes(response.data.numberOfLikes);

        const currentUserResponse = await axios.get(`/users/${currentUserId}`);
        setContactForm(prevForm => ({
          ...prevForm,
          name: currentUserResponse.data.fullName,
          email: currentUserResponse.data.email
        }));

        // Fetch sector names
        await fetchSectorNames(response.data.sectorId);

        // Fetch status names
        await fetchStatusNames(response.data.innovationStatusId);

        // Check if current user has liked this innovation
        if (currentUserResponse.data.favoritedInnovations.includes(id)) {
          setLiked(true);
        }
      } catch (error) {
        console.error('Error fetching innovation details:', error);
      }
    };

    fetchInnovationDetail();
  }, [id, currentUserId]);

  // Fetch sector names based on sector ID
  const fetchSectorNames = async (sectorId) => {
    try {
      const response = await axios.get(`/sectors/${sectorId}`);
      setListOfSectors(prevSectors => [...prevSectors, { id: sectorId, name: response.data.name }]);
    } catch (error) {
      console.error(`Error fetching sector with ID ${sectorId}:`, error);
      setListOfSectors(prevSectors => [...prevSectors, { id: sectorId, name: 'Unknown' }]);
    }
  };

  // Fetch status names based on status ID
  const fetchStatusNames = async (statusId) => {
    try {
      const response = await axios.get(`/status/${statusId}`);
      setListOfStatuses(prevStatuses => [...prevStatuses, { id: statusId, name: response.data.name }]);
    } catch (error) {
      console.error(`Error fetching status with ID ${statusId}:`, error);
      setListOfStatuses(prevStatuses => [...prevStatuses, { id: statusId, name: 'Unknown' }]);
    }
  };

  // Function to get sector name based on sector ID
  const getSectorName = (sectorId) => {
    const sector = listOfSectors.find(sector => sector.id === sectorId);
    return sector ? sector.name : "Unknown Sector";
  };

  // Function to get status name based on status ID
  const getStatusName = (statusId) => {
    const status = listOfStatuses.find(status => status.id === statusId);
    return status ? status.name : "Unknown Status";
  };

  const handleLike = async () => {
    try {
      await axios.put(`/innovations/${id}/favorite`);
      setLiked(!liked);
      setNumberOfLikes(liked ? numberOfLikes - 1 : numberOfLikes + 1);
    } catch (error) {
      console.error('Error liking the innovation:', error);
    }
  };

  const shareInnovation = () => {
    setOpenShareDialog(true);
  };

  const closeShareDialog = () => {
    setOpenShareDialog(false);
  };

  const shareToPlatform = (platform) => {
    const shareUrl = window.location.href;
    let platformShareUrl = '';

    switch (platform) {
      case 'facebook':
        platformShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        platformShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'linkedin':
        platformShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        break;
      default:
        break;
    }

    if (platformShareUrl !== '') {
      window.open(platformShareUrl, '_blank');
    }

    setOpenShareDialog(false);
  };

  const handleEditClick = () => {
    setOpenEditDialog(true);
  };

  const handleEditClose = () => {
    setOpenEditDialog(false);
  };

  const handleUpdateInnovation = (updatedInnovation) => {
    setInnovation(updatedInnovation);
  };

  const handleContactClick = () => {
    setOpenContactDialog(true);
  };

  const handleContactClose = () => {
    setOpenContactDialog(false);
  };

  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactForm(prevForm => ({ ...prevForm, [name]: value }));
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/auth/contact/${innovation.userId}`, contactForm);
      setOpenContactDialog(false);
      setAlertMessage('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
      setAlertMessage('Error sending message. Please try again later.');
    }
  };

  // Function to get the creator's name
  const getCreatorName = (innovation, creatorName) => {
    if (innovation && innovation.firstName && innovation.lastName) {
      return `${innovation.firstName} ${innovation.lastName}`;
    }
    return creatorName;
  };

  return (
    <Layout>
<Box 

  marginTop='100px' // Reduced marginTop
  paddingBottom='20px' 
  style={{ backgroundColor: 'transparent' }}
>
        {innovation ? (
          <Grid container spacing={2} style={{ backgroundColor: '#f0f0f0',marginLeft:'0px',marginRight:'10px', padding: '10px' }}>
            <Grid item xs={12} md={7}>
            <Card style={{ padding: '8px', boxShadow: 'none', borderRadius: '8px', width: '100%', height: '400px' }}>
  <Carousel animation="slide">
    {innovation.images && innovation.images.map((image, index) => (
      <CardMedia
        key={index}
        component="img"
        image={image}
        alt={`Additional Innovation Image ${index + 1}`}
        style={{ height: '300px', objectFit: 'contain', borderRadius: '8px' }}  
      />
    ))}
  </Carousel>
</Card>

            </Grid>
            <Grid item xs={12} md={5}>
              <Card style={{ padding: '16px', boxShadow: 'none', borderRadius: '8px' }}>
                <Box
                  component="div"
                  bgcolor="white"
                  borderRadius={2}
                  p={2}
                  display="inline-block"
                  width="100%"
                >
                  <Typography
    component="div"
    variant="h6"
    sx={{
      fontWeight: 'bold',
      fontSize: '1.25rem',
      color: '#333',
      marginBottom: '12px', // Equal spacing
    }}
  >
                    {innovation.innovationName}
                  </Typography>
                  <hr />
                  <Typography gutterBottom style={{ fontFamily: 'Noto Sans', color: 'orange' }}>
                    Innovator
                  </Typography>
                  <Typography variant="h6" gutterBottom style={{ fontFamily: 'Noto Sans', color: 'grey' }}>
                    {getCreatorName(innovation, creatorName)}
                  </Typography>
                  <Typography gutterBottom style={{ fontFamily: 'Noto Sans', color: 'orange' }}>
                    Sector
                  </Typography>
                  <Typography variant="h6" gutterBottom style={{ fontFamily: 'Noto Sans', color: 'grey' }}>
                    {getSectorName(innovation.sectorId)}
                  </Typography>
                  <Typography gutterBottom style={{ fontFamily: 'Noto Sans', color: 'orange' }}>
                    Innovation Status
                  </Typography>
                  <Typography variant="h6" gutterBottom style={{ fontFamily: 'Noto Sans', color: 'grey' }}>
                    {getStatusName(innovation.innovationStatusId)}
                  </Typography>
                  <CardActions>
                    <IconButton onClick={handleLike} aria-label="add to favorites">
                      {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </IconButton>
                    <IconButton aria-label="share" onClick={shareInnovation}>
                      <ShareIcon />
                    </IconButton>
                    <IconButton onClick={handleEditClick} aria-label="edit" >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={handleContactClick} disabled={isUserInnovation}>
                      <EmailIcon />
                    </IconButton>
                  </CardActions>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box
              
                borderRadius={2}
             
                style={{ marginBottom: '20px', minHeight: '300px' }} // Adjust height if needed
              >
                <Typography variant="h5" gutterBottom style={{ fontFamily: 'Noto Sans', marginBottom: '10px' }}>Description</Typography>
                <Typography style={{ textAlign: 'justify' }}>{innovation.description}</Typography>

                <Typography variant="h5" gutterBottom style={{ fontFamily: 'Noto Sans', marginBottom: '10px', marginTop: '20px' }}>Problem Statement</Typography>
                <Typography style={{ textAlign: 'justify' }}>{innovation.problemStatement}</Typography>

                <Typography variant="h5" gutterBottom style={{ fontFamily: 'Noto Sans', marginBottom: '10px', marginTop: '20px' }}>Solution</Typography>
                <Typography style={{ textAlign: 'justify' }}>{innovation.solution}</Typography>

                <Typography variant="h5" gutterBottom style={{ fontFamily: 'Noto Sans', marginBottom: '10px', marginTop: '20px' }}>Impact</Typography>
                <Typography style={{ textAlign: 'justify' }}>{innovation.tractionAndImpact}</Typography>

                <Typography variant="h5" gutterBottom style={{ fontFamily: 'Noto Sans', marginBottom: '10px', marginTop: '20px' }}>Plan for Expansion</Typography>
                <Typography style={{ textAlign: 'justify' }}>{innovation.planForExpansion}</Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h6">
            Loading innovation details...
          </Typography>
        )}
      </Box>
      <Dialog open={openShareDialog} onClose={closeShareDialog}>
        <DialogTitle>Share Innovation</DialogTitle>
        <DialogContent>
          <Button onClick={() => shareToPlatform('facebook')}>Share to Facebook</Button>
          <Button onClick={() => shareToPlatform('twitter')}>Share to Twitter</Button>
          <Button onClick={() => shareToPlatform('linkedin')}>Share to LinkedIn</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeShareDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openContactDialog} onClose={handleContactClose}>
        <DialogTitle>Contact Innovation Owner</DialogTitle>
        <DialogContent>
          <form onSubmit={handleContactFormSubmit}>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Your Name"
              type="text"
              fullWidth
              variant="outlined"
              value={contactForm.name}
              onChange={handleContactFormChange}
              required
            />
            <TextField
              margin="dense"
              name="email"
              label="Your Email"
              type="email"
              fullWidth
              variant="outlined"
              value={contactForm.email}
              onChange={handleContactFormChange}
              required
            />
            <TextField
              margin="dense"
              name="message"
              label="Your Message"
              type="text"
              fullWidth
              variant="outlined"
              value={contactForm.message}
              onChange={handleContactFormChange}
              multiline
              rows={4}
              required
            />
            <DialogActions>
              <Button onClick={handleContactClose}>Cancel</Button>
              <Button type="submit" color="primary">
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <EditInnovationForm
        innovation={innovation}
        open={openEditDialog}
        onClose={handleEditClose}
        onUpdate={handleUpdateInnovation}
      />
      <Dialog open={alertMessage !== null} onClose={() => setAlertMessage(null)}>
        <DialogTitle>{alertMessage === 'Message sent successfully' ? 'Success!' : 'Error'}</DialogTitle>
        <DialogContent>
          <p>{alertMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertMessage(null)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default InnovationDetail;
