import { authConstants } from "../actions/constants";

const initState = {
  token: null,
  userId: "",

  authenticate: false,
  authenticating: false,
};

const authReducer = function (state = initState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        authenticating: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.token,
        authenticate: true,
        authenticating: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default authReducer;
