import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  Link,
  Avatar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
const placeholderImage = 'img/placeholder.jpg'
const InnovatorsPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/users/getAll');
        setUsers(response.data);
        setFilteredUsers(response.data.filter(user => user.role === 'innovator'));
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.fullName.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredUsers(filtered.filter(user => user.role === 'innovator'));
  }, [filter, users]);

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  return (
    <Layout>
      <Box marginTop='100px' paddingBottom='60px'>
        <Typography variant="h6" gutterBottom>
          List of Innovators
        </Typography>
        <TextField
          id="filter"
          label="Filter by name"
          value={filter}
          onChange={handleFilterChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Profile Picture</TableCell> 
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone No.</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Gender</TableCell>
               
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user._id}>
                   <TableCell>
                    <Avatar
                      alt={user.fullName}
                      src={user.profilePicture || placeholderImage}
                    />
                  </TableCell>
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.address}</TableCell>
                  <TableCell>{user.city}</TableCell>
                  <TableCell>{user.gender}</TableCell>
                 
                  <TableCell>
                    {/* Link to the detail page */}
                    <Link
                      component={RouterLink}
                      to={`/innovator/${user._id}`}
                      underline="none"
                    >
                      View Details
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default InnovatorsPage;
