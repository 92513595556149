import React from "react";
import "./index.css";
import ReactDOM from "react-dom";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <DarkModeContextProvider>
          <App />
        </DarkModeContextProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);
