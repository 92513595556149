import { productConstants } from "../actions/constants";
export const TOGGLE_PRODUCT_MODAL = "toggle-modal";

const initialState = {
  products: [],
  modal: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productConstants.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case TOGGLE_PRODUCT_MODAL:
      return {
        ...state,
        modal: !state.modal,
      };
    default:
      return state;
  }
};

export const productsSelector = (state) => state.product.products;
export const modalSelector = (state) => state.product.modal;

export default productReducer;
