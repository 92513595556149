import React, { useEffect, useState } from 'react';
import axios from "../../helpers/axios";
import Layout from '../home/layout';
import { ListGroup, Spinner, Alert, Row, Col, Modal, Button } from 'react-bootstrap';
import { Typography } from '@mui/material';

const SentMessages = () => {
    const [userId, setUserId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [selectedMessage, setSelectedMessage] = useState(null); // State to store selected message

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await axios.get(`/users/profile/${userId}`);
                setUserId(response.data._id);
            } catch (err) {
                setError('Error fetching user information.');
                setLoading(false);
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        const fetchSentMessages = async () => {
            try {
                if (!userId) {
                    return;
                }
                const response = await axios.get(`/users/messages/sent/${userId}`);
                // Sort messages by createdAt in descending order
                const sortedMessages = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setMessages(sortedMessages);
                setLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'Error fetching sent messages.');
                setLoading(false);
            }
        };

        fetchSentMessages();

        // Set interval to auto-reload the page every 10 seconds
        const interval = setInterval(fetchSentMessages, 10000);
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [userId]);

    const handleOpenModal = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedMessage(null);
        setShowModal(false);
    };

    return (
        <Layout>
            <div className="sent-messages-container">
                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : messages.length === 0 ? (
                    <Alert variant="info">No messages found.</Alert>
                ) : (
                    <ListGroup>
                        {messages.map((message) => (
                            <ListGroup.Item key={message._id} onClick={() => handleOpenModal(message)} style={{ cursor: 'pointer' }}>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Typography className="mb-1">To: {message.recipient.fullName}</Typography>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Typography className="mb-1">{message.subject}</Typography>
                                        <small className="text-muted">Sent: {new Date(message.createdAt).toLocaleString()}</small>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}

                {/* Modal for displaying message details */}
                <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Sent Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMessage && (
                            <>
                                <Typography variant="h5">{selectedMessage.subject}</Typography>
                                <Typography>To: {selectedMessage.recipient.fullName}</Typography>
                                <Typography>Sent: {new Date(selectedMessage.createdAt).toLocaleString()}</Typography>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }} />
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Layout>
    );
};

export default SentMessages;
