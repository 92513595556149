import React, { useEffect, useState } from 'react';
import axios from "../../helpers/axios";
import Layout from '../home/layout';
import { ListGroup, Spinner, Alert, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { Typography } from '@mui/material';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const ReceivedMessages = () => {
    const [userId, setUserId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [replyContent, setReplyContent] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await axios.get(`/users/profile/${userId}`);
                setUserId(response.data._id);
            } catch (err) {
                setError('Error fetching user information.');
                setLoading(false);
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                if (!userId) {
                    return;
                }
                const response = await axios.get(`/users/messages/${userId}`);
                const sortedMessages = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setMessages(sortedMessages);
                setLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'Error fetching messages.');
                setLoading(false);
            }
        };

        fetchMessages();

        const interval = setInterval(fetchMessages, 10000);
        return () => clearInterval(interval);
    }, [userId]);

    const handleOpenModal = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
        markMessageAsRead(message._id);
    };

    const handleCloseModal = () => {
        setSelectedMessage(null);
        setShowModal(false);
    };

    const handleReplyChange = (value) => {
        setReplyContent(value);
    };

    const handleSendReply = async () => {
        try {
            const response = await axios.post(`/users/message/reply/${selectedMessage._id}`, {
                content: replyContent,
            });

            const updatedMessages = [...messages];
            const index = updatedMessages.findIndex(msg => msg._id === selectedMessage._id);
            if (index !== -1) {
                updatedMessages[index] = response.data.message;
                const sortedMessages = updatedMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setMessages(sortedMessages);
            }

            setReplyContent('');
            setShowModal(false);

            swal({
                icon: 'success',
                title: 'Reply Sent!',
                text: 'Your reply has been sent successfully.',
            });
        } catch (err) {
            setError(err.response?.data?.message || err.message || 'Error sending reply.');
        }
    };

    const markMessageAsRead = async (messageId) => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('No token available');
            }

            await axios.put(`/users/message/${messageId}/mark-read`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const updatedMessages = messages.map(msg =>
                msg._id === messageId ? { ...msg, read: true } : msg
            );
            setMessages(updatedMessages);
        } catch (err) {
            console.error('Error marking message as read:', err);
        }
    };

    const filteredMessages = messages.filter(
        message =>
            message.sender?.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            message.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
            message.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Layout>
            <div className="received-messages-container">
                <Form.Control
                    type="text"
                    placeholder="Search messages"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-3"
                />

                {loading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : messages.length === 0 ? (
                    <Alert variant="info">No messages found.</Alert>
                ) : (
                    <ListGroup variant="flush">
                        {filteredMessages.map((message) => (
                            <ListGroup.Item
                                key={message._id}
                                onClick={() => handleOpenModal(message)}
                                style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                            >
                                <Row className="align-items-center">
                                    <Col xs={12} md={4}>
                                        <Typography className="mb-1">{message.sender?.fullName || 'Unknown Sender'}</Typography>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Typography className="mb-1">{message.subject}</Typography>
                                    </Col>
                                    <Col xs={12} md={4} className="text-md-end">
                                        <small className="text-muted">Received: {new Date(message.createdAt).toLocaleString()}</small>
                                        {!message.read && <span className="badge bg-secondary ms-2">Unread</span>}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}

                <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedMessage && selectedMessage.sender && (
                            <>
                                <Typography>Subject: <b>{selectedMessage.subject}</b></Typography>
                                <hr />
                                <Typography>From: {selectedMessage.sender.fullName}</Typography>
                                <Typography>Received: {new Date(selectedMessage.createdAt).toLocaleString()}</Typography>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }} />
                                <hr />
                                <Form.Group controlId="replyForm">
                                    <Form.Label>Reply:</Form.Label>
                                    <ReactQuill
                                        value={replyContent}
                                        onChange={handleReplyChange}
                                    />
                                </Form.Group>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSendReply}>
                            Send Reply
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </Layout>
    );
};

export default ReceivedMessages;
