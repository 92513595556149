import swal from "sweetalert";
import axios from "../helpers/axios";
import { uploadFile } from "../helpers/functions";
import { TOGGLE_INNOVATION_MODAL } from "../reducers/innovation.reducer";
import { innovationConstants } from "./constants";
import {useNavigate} from "react-router";

// new action
export const getInnovations = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: innovationConstants.GET_ALL_INNOVATIONS_REQUEST });
      const res = await axios.get(
        `innovations/byUser/${localStorage.getItem("userId")}`
      );

      if (res.status === 200) {
        const innovations = res.data;
        dispatch({
          type: innovationConstants.GET_ALL_INNOVATIONS_SUCCESS,
          payload: innovations,
        });
      } else {
        dispatch({ type: innovationConstants.GET_ALL_INNOVATIONS_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// add action
export const addInnovation = (data) => {
  return async (dispatch) => {
    if (data.image instanceof File) {
      try {
        const image = await uploadFile(data.image);
        data.image = image.data.url;
      } catch (err) {
        console.log("image upload error", err);
      }
    }
    try {
      await axios.post("/innovations/create", {
        ...data,
        images: ["noimage.jpg"],
      });
      dispatch(getInnovations());
      await swal("Success", "Innovation added successfully", "success")


    } catch (error) {
      swal("Failure", "Failed to add innovation", "error");
    }
  };
};

// edit an innovation
export const editInnovation = (data, innovationId) => {
  return async (dispatch) => {
    if (data.image instanceof File) {
      try {
        const image = await uploadFile(data.image);
        data.image = image.data.url;
      } catch (err) {
        console.log("image upload error", err);
      }
    }

    try {
      await axios.put(`/innovations/update/${innovationId}`, data);
      dispatch(getInnovations());
      await swal("Success", "Innovation has been updated successfully", "success")
    } catch (error) {
      await swal("Failure", "Failed to update Innovation. Please try again", "error");
    }
  };
};

// new action
export const deleteInnovationById = (innovationId) => {
  return async (dispatch) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`/innovations/${innovationId}`)
          .then(() => {
            dispatch(getInnovations());
            swal("Record has been deleted!", {
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            swal("Operation failed. Your data is safe!");
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
};


export const toggleInnovationModal = () => {
  return {
    type: TOGGLE_INNOVATION_MODAL,
  };
};
