import React, {useState} from 'react'
import {Box, styled} from '@mui/system'
import {Card, Button} from '@mui/material'
import {Input, Form} from 'reactstrap';
import {useNavigate} from "react-router";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

styled('img')(() => ({
    width: '100%',
}));
const JWTRegister = styled(JustifyBox)(() => ({
    background: '#1A2038',
    minHeight: '100vh !important',
    '& .card': {
        maxWidth: 500,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const formDataInitial = {
    fullName: '',
    email: '',
    password: ''
};

const VerifyAccount = () => {

    const [formData, setFormData] = useState(formDataInitial);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setFormData(prev => ({...prev, [name]: value}));
    }



    return (
        <JWTRegister>
            <Card className="card">
                    <div className="d-flex flex-column">
                        <div className='d-flex align-items-center justify-content-center mt-2'>
                            <Box component="img" src="/static/logo.png" sx={{ width: 60, height: 60 }} />
                        </div>
                        <Box p={4} height="100%">
                            <h4>Enter account verification code</h4>
                            <p className='alert alert-info mb-3'>Please check your email for verification code</p>
                            <Form>
                                <Input
                                    sx={{mb: '16px'}}
                                    variant="outlined"
                                    value={formData.password}
                                    onChange={handleChange}
                                    name="password" type="password"
                                    placeholder="Verification Code" required/>
                                <div className='mt-2'></div>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    value="Register"
                                    disabled={isLoading}
                                    sx={{textTransform: 'capitalize'}}>
                                    {isLoading ? 'Please wait...' : 'Verify'}
                                </Button>
                            </Form>
                        </Box>
                    </div>
            </Card>
        </JWTRegister>
    )
}

export default VerifyAccount;
