import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  IconButton,
  CardMedia,
  TextField,
} from '@mui/material'; // Import Material-UI components
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const PartnersPage = () => {
  const [partners, setPartners] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredPartners, setFilteredPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/admin/partners', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('API response:', response.data); // Log the API response

        if (response.data && response.data.success && Array.isArray(response.data.data)) {
          setPartners(response.data.data);
          setFilteredPartners(response.data.data);
        } else {
          console.error('API response does not contain a valid data array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };

    fetchPartners();
  }, []);

  useEffect(() => {
    setFilteredPartners(
      partners.filter(partner =>
        partner.fullName.toLowerCase().includes(search.toLowerCase()) ||
        partner.email.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, partners]);

  const handleDelete = async (partnerId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.delete(`https://api.ghsdginnovations.org/api/admin/partners/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // Fetch updated partner list after deletion
      const response = await axios.get('https://api.ghsdginnovations.org/api/admin/partners', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setPartners(response.data.data);
        setFilteredPartners(response.data.data);
      } else {
        console.error('API response does not contain a valid data array:', response.data);
      }
    } catch (error) {
      console.error('Error deleting partner:', error);
    }
  };

  return (
    <Layout>
      <div style={{ marginTop: '64px', padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
          List of Partners
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPartners.map((partner) => (
                <TableRow key={partner._id}>
                 
                  <TableCell>{partner.fullName}</TableCell>
                  <TableCell>{partner.email}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(partner._id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton aria-label="edit">
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Layout>
  );
};

export default PartnersPage;
