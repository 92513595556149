import { useState, useEffect } from 'react';
import { api } from '../urlConfig';
import axios from 'axios';

function useRegion() {
  const [listOfRegions, setListOfRegions] = useState([]);

  useEffect(() => {
    axios.get(`${api}regions/all`).then((response) => {
      setListOfRegions(response.data);
    }).catch((error) => {
      // Handle error if the request fails
      console.error('Error fetching regions:', error);
    });
  }, []);

  return [listOfRegions];
}

export default useRegion;
