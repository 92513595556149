import "./style.css";
import {Link} from "react-router-dom";
import {Input} from 'reactstrap';
import {Form} from "formik";
import "./style.css"
import Logo from "../../components/Logo";

export default function Reset() {

    return (
        <div className="login">
            <div className="login_wrapper">
                <div className="login_wrap">
                    <div className="login_2_wrap">
                        <Logo/>
                        <Form>
                            <Input
                                sx={{mb: 3, width: '100%'}}
                                variant="outlined"
                                size="small"
                                label="Email"
                                type="email"/>
                            <br/> <br/>
                            <button
                                className="blue_btn"
                                type="submit"
                                color="primary"
                                value="Login">
                                Login
                            </button>
                            <Link to="/reset" className="forgot_password">
                                Forgotten password?
                            </Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    );
}
