import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import Layout from './layout';
import {
  Avatar,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  CardMedia,
  CardActions,
  Link
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import WebIcon from '@mui/icons-material/Web';

const defaultProfilePicture = "https://via.placeholder.com/150"; // Placeholder for profile picture

const UserDetailPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [sectorName, setSectorName] = useState('');
  const [similarProfiles, setSimilarProfiles] = useState([]);
  const [displayedProfiles, setDisplayedProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchUser = async () => {
    try {
      const userResponse = await axios.get(`https://api.ghsdginnovations.org/api/users/${id}`);
      setUser(userResponse.data);

      // Fetch sector name if user role is innovator
      if (userResponse.data.role === 'innovator') {
        const sectorResponse = await axios.get(`https://api.ghsdginnovations.org/api/sectors/${userResponse.data.sectorId}`);
        setSectorName(sectorResponse.data.name);
      }

      // Fetch similar profiles
      const similarResponse = await axios.get(`https://api.ghsdginnovations.org/api/users/similar/${id}`);
      setSimilarProfiles(similarResponse.data);
      setDisplayedProfiles(similarResponse.data.slice(0, 6)); // Display first 6 profiles initially
      setHasMore(similarResponse.data.length > 6); // Check if there are more profiles to load
    } catch (error) {
      console.error('Error fetching user or similar profiles:', error);
    }
  };

  const loadMoreProfiles = useCallback(() => {
    if (hasMore) {
      const newPage = page + 1;
      const newProfiles = similarProfiles.slice(0, newPage * 6);
      setDisplayedProfiles(newProfiles);
      setPage(newPage);
      setHasMore(newProfiles.length < similarProfiles.length);
    }
  }, [page, similarProfiles, hasMore]);

  useEffect(() => {
    fetchUser();
  }, [id]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreProfiles();
        }
      },
      { threshold: 1.0 }
    );

    const target = document.querySelector('#load-more-trigger');
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [loadMoreProfiles]);

  if (!user) {
    return (
      <Layout>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container spacing={3} style={{ marginTop: '100px', marginLeft: '45px', marginBottom: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card
              elevation={0}
              sx={{
                border: '1px solid #ddd',
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      src={user.profilePicture || defaultProfilePicture}
                      alt="Profile Image"
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{user.fullName}</Typography>
                    <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                      @{user.role}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              elevation={0}
              sx={{
                marginTop: '20px',
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography variant="h6">About</Typography>
                <Typography variant="body1">{user.bio || "No bio available"}</Typography>
              </CardContent>
            </Card>
            {user.role === 'innovator' && (
              <Card
                elevation={0}
                sx={{
                  marginTop: '20px',
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  boxShadow: 1,
                  '&:hover': {
                    boxShadow: 3,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6">Company Details</Typography>
                  <Box display="flex" alignItems="center" mb={1}>
                    <BusinessIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{user.businessName || "No business details available"}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <WorkIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{sectorName || "No sector details available"}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <WebIcon sx={{ marginRight: '8px' }} />
                    <Typography variant="body1">{user.web || "No website details available"}</Typography>
                  </Box>
                  <hr />
                  <p>ELEVATOR PITCH</p>
                  <hr />
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="body1">{user.elevatorPitch || "No elevator pitch available"}</Typography>
                  </Box>
                </CardContent>
              </Card>
            )}
            <Card
              sx={{
                marginTop: '20px',
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography variant="h6">Contact</Typography>
                <Box>
                  {user.email && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <EmailIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.email}</Typography>
                    </Box>
                  )}
                  {user.phone && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <PhoneIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.phone}</Typography>
                    </Box>
                  )}
                  {user.city && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocationCityIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.city}</Typography>
                    </Box>
                  )}
                  {user.address && (
                    <Box display="flex" alignItems="center" mb={1}>
                      <HomeIcon sx={{ marginRight: '8px' }} />
                      <Typography variant="body1">{user.address}</Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card
              sx={{
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Similar Profiles
                </Typography>
                {displayedProfiles.length > 0 ? (
                  <Grid container direction="column" spacing={2}>
                    {displayedProfiles.map(profile => (
                      <Grid item key={profile._id}>
                        <Card
                          elevation={0}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                              transform: 'scale(1.05)', // Scales the card up on hover
                              boxShadow: 3, // Optional: add a shadow effect on hover
                            },
                            border: '1px solid #ddd',
                            borderRadius: 2,
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              width: 80,
                              height: 80,
                              borderRadius: '50%',
                              margin: 1,
                            }}
                            image={profile.profilePicture || defaultProfilePicture}
                            alt={profile.fullName}
                          />
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="body1" component="div">
                              {profile.fullName}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Link
                              component={RouterLink}
                              to={`/manageUsers/${profile._id}`}
                              underline="none"
                            >
                              View Details
                            </Link>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}

                    {hasMore && <div id="load-more-trigger" style={{ height: '1px' }} />}
                  </Grid>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No similar profiles available
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default UserDetailPage;
